@import url("https://fonts.googleapis.com/css?family=Saira+Semi+Condensed&display=swap");
* {
  font-family: "Saira Semi Condensed", sans-serif;
  /* transition: all 0.5s ease; */
}

.addButton {
  color: green;
  transform: scale(3);
  padding-left: 10px;
  filter: drop-shadow(0px 0px 5px rgba(0, 255, 51, 0.9));
  transition: margin 0.2s ease-in-out !important;
}

input {
  box-shadow: rgba(55, 245, 255, 0.3) 0px 19px 38px,
    rgba(55, 245, 255, 0.22) 0px 15px 12px;
}

.addButton:hover {
  cursor: pointer;
  /* transform: scale(3.5); */
  margin-top: -10px;
}

.formDiv {
  padding: 0.4rem;
  font-size: 1.1rem;
}

form {
  width: 400px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

form div,
form label,
form textarea {
  width: 100%;
}

form input,
form select {
  width: 91%;
}

.field:nth-of-type(2) {
  margin: 16px 0;
}

label,
input,
select,
textarea {
  padding: 8px;
}

label {
  margin-left: -60px;
}

label,
[placeholder] {
  color: rgb(229, 229, 229);
}

label i {
  margin: 0 10px 0 0;
}

.field:focus-within label {
  color: #000;
  letter-spacing: 2px;
}

input,
select,
textarea {
  background: rgba(255, 255, 255, 0.79);
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 6px -6px #555;
}
input:focus,
textarea:focus,
select:focus {
  background: white;
  box-shadow: none;
}

textarea {
  resize: none;
}
textarea::-webkit-scrollbar {
  width: 0;
}

button {
  background: #c9c9c9;
  margin: 16px 0 50px 0;
  padding: 8px 16px;
  color: rgb(0, 55, 123);
  border: none;
  border-radius: 8px !important;
  cursor: pointer;
  box-shadow: 0 5px 6px -2px rgba(0, 242, 255, 0.549);
  transition: 0.2s ease-in-out;
}
button:hover {
  /* margin-top: -10px; */
  letter-spacing: 2px;
  /* box-shadow: none; */
  box-shadow: 0 5px 9px -2px rgb(0, 242, 255);
}

@media (max-width: 425px) {
  form {
    width: 100%;
  }
}
