.formRow {
  font-size: 1.2rem;
  color: #bbc3c8;
  font-family: "Verdana", sans-serif;
  line-height: 26px;
  text-indent: 30px;
  /* margin-top: 40px; */
}

.button {
  margin-left: -30px;
}
