.text {
  color: rgb(229, 229, 229);
  font-family: "Verdana", sans-serif;
  font-size: 1.5rem;
  /* line-height: 26px; */
  /* text-indent: 30px; */
  margin: 0;
  /* border: 2px red solid; */
  text-align: center;
  box-shadow: rgba(55, 245, 255, 0.3) 0px 19px 38px,
    rgba(55, 245, 255, 0.22) 0px 15px 12px;
  border-radius: 2%;
}

li {
  list-style: none;
}
