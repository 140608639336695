.list {
  padding: 0;
  transition: 0.2s ease-in-out !important;
}

.removeButton {
  pointer-events: none;
  color: rgb(255, 0, 0);
  margin: 0 5px;
  /* transform: scale(); */
  filter: drop-shadow(0px 0px 3px rgb(255, 72, 72));
}

.list:hover {
  cursor: pointer;
  color: rgb(255, 83, 83);
}

ul {
  text-align: left;
  overflow-wrap: break-word;
}
